import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { UsersService } from '../../@core/services/users.service';

@Component({
  selector: 'ngx-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent {
  displayCompanyInfo = true;
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  companyEmail: string;
  err: any[] = [];

  constructor(
    private usersService: UsersService,
    private router: Router,
    private toastrService: NbToastrService,
  ) { }

  submit() {
    this.usersService
      .register({
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        name: this.name,
        companyEmail: this.companyEmail,
      })
      .subscribe(
        (res: any) => {
          this.router.navigate(['../..']);
        },
        (err) => {
          this.err.push(err);
          window.scrollTo(0, 0);
          this.email = '';
          this.toastrService.show(err.error.message, `Oops`, {
            status: 'danger',
          });
          this.err = [];
          this.err.push(err.error.message);
        },
      );
  }
}
