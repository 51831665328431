import { Component, OnInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent
  implements OnInit {
  password: string;
  confirmPassword: string;
  token: string;

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.token = params['token'];
    });
  }

  submit() {
    this.authService
      .resetPassword('email', {
        token: this.token,
        password1: this.password,
        password2: this.confirmPassword,
      })
      .subscribe(
        (response) => {
          this.router.navigate(['../..']);
        },
        (err) => {},
      );
  }
}
