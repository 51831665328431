import { Component } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  displayCompanyInfo = true;
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  organisationName: string;
  cui: string;
  period: string;
  registration_no: string;
  bank: string;
  adress: string;
  phone: string;
  err: any[] = [];
  selectedRole = 'company_admin';
  roles = [
    {
      value: 'company_admin',
      name: 'Company',
    },
    {
      value: 'catering_admin',
      name: 'Catering',
    },
  ];

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private toastrService: NbToastrService,
  ) {}

  submit() {
    this.authService
      .register('email', {
        name: this.name,
        login: this.email,
        password1: this.password,
        password2: this.confirmPassword,
        organisationName: this.organisationName,
        role: this.selectedRole,
        period: 'null',
        bank: this.bank,
        phone: this.phone,
        registration_no: this.registration_no,
        cui: this.cui,
        adress: this.adress,
      })
      .subscribe(
        (res) => {
          if (res.getResponse().status === 200) {
            this.router.navigate(['../..']);
          } else {
            window.scrollTo(0, 0);
            this.email = '';
            this.toastrService.show(res.getResponse().error.message, `Oops`, {
              status: 'danger',
            });
            this.err = [];
            this.err.push(res.getResponse().error.message);
          }
        },
        (err) => {
          this.err.push(err);
        },
      );
  }
}
