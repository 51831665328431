import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServerDataSource } from 'ng2-smart-table';
import { NbAuthOAuth2JWTToken, NbTokenService, NbAuthService } from '@nebular/auth';

@Injectable()
export class MenuService {
  constructor(private http: HttpClient, private tokenService: NbTokenService, private authService: NbAuthService) { }

  getAccountConfig(): Observable<any> {
    return this.http.get('/api/settings/account/config');
  }

  saveAccountConfig(data): Observable<any> {
    return this.http.post('/api/settings/account/config', data).pipe(
      map((config: any) => {
        this.tokenService
          .get()
          .subscribe((token: NbAuthOAuth2JWTToken) => {
            this.authService.refreshToken(token.getOwnerStrategyName(), token).subscribe();
          });

        return config;
      }),
    );
  }

  getMenu(): Observable<any> {
    return this.http.get('/api/menus');
  }

  getMenuCurrent(): Observable<any> {
    return this.http.get('/api/menus/current').pipe(
      catchError((e: any) => {
        return of(null);
      }),
    );
  }

  getMenuNext(): Observable<any> {
    return this.http.get('/api/menus/next').pipe(
      catchError((e: any) => {
        return of(null);
      }),
    );
  }

  getMenuById(id): Observable<any> {
    return this.http.get('/api/menus/' + id);
  }

  saveRating(rate: any, id: any) {
    return this.http.post('/api/ratings/', { rate, id });
  }

  getRatings(id: any) {
    return this.http.get('api/ratings/' + id);
  }

  removeRatingById(id: any) {
    return this.http.delete('api/ratings/' + id);
  }

  getUserMenuById(id): Observable<any> {
    return this.http.get('/api/user-menus/' + id);
  }

  getUserMenuCurrent(): Observable<any> {
    return this.http.get('/api/user-menus/current').pipe(
      catchError((e: any) => {
        return of(null);
      }),
    );
  }

  getMenuItemsTypeAhead(term): Observable<any> {
    if (term === '') {
      return of([]);
    }

    return this.http.get('/api/menus/items', { params: { term } });
  }

  getUserMenuNext(): Observable<any> {
    return this.http.get('/api/user-menus/next').pipe(
      catchError((e: any) => {
        return of({});
      }),
    );
  }

  saveMenu(menuId, menu) {
    return this.http.post('/api/user-menus', { menuId, menu });
  }

  saveAndDone(menuId, menu) {
    return this.http.post('/api/user-menus', { menuId, menu, done: true });
  }

  addMenuFromUrl(params: any): Observable<any> {
    return this.http.post('/api/menus/saveFromUrl', params);
  }

  addMenu(params: any): Observable<any> {
    return this.http.post('/api/menus', params);
  }

  getMenus(params: any): Observable<any> {
    return this.http.get('/api/menus', params);
  }

  deleteMenu(id: any): Observable<any> {
    return this.http.delete('/api/menus/' + id);
  }

  getMenusSmartTable() {
    return new ServerDataSource(this.http, {
      endPoint: '/api/menus',
      dataKey: 'docs',
      totalKey: 'total',
      perPage: 'limit',
      pagerPageKey: 'page',
      filterFieldKey: 'filters[#field#]',
    });
  }

  getOrdersSmartTable() {
    return new ServerDataSource(this.http, {
      endPoint: '/api/orders',
      dataKey: 'docs',
      totalKey: 'total',
      perPage: 'limit',
      pagerPageKey: 'page',
      filterFieldKey: 'filters[#field#]',
    });
  }

  getUniqueCustomers() {
    return new ServerDataSource(this.http, {
      endPoint: '/api/orders/customers',
      dataKey: 'docs',
      totalKey: 'total',
      perPage: 'limit',
      pagerPageKey: 'page',
      filterFieldKey: 'filters[#field#]',
    });
  }

  getCateringProviders(): Observable<any> {
    return this.http.get('/api/customers/catering');
  }

  getOrderById(id): Observable<any> {
    return this.http.get('/api/orders/' + id);
  }

  sendOrder(order): Observable<any> {
    return this.http.post('/api/orders', order);
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post('/api/menus/uploadFile', formData);
  }

  getMenuFromDoc(name): Observable<any> {
    return this.http.get('/api/menus/getMenuFromDoc', {
      params: { name: name },
    });
  }

  getPrecompletedOrder(): Observable<any> {
    return this.http.get('/api/orders/precomplete');
  }

  getOrders(): Observable<any> {
    return this.http.get('/api/orders/');
  }

  getCompletedOrders(sent?: any, menuId?: any): Observable<any> {
    const params: any = {};

    if (menuId) params.menuId = menuId;

    return this.http.get('/api/orders/completed', { params });
  }

  getSentCompletedOrders(menuId?: any): Observable<any> {
    const params: any = {
      sent: 'true',
    };

    if (menuId) params.menuId = menuId;

    return this.http.get('/api/orders/completed', { params });
  }


}
