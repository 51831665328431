import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../services/users.service';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private usersService: UsersService,
        private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        const roles = route.data['roles'].map((elem: string) => elem);

        const type = this.usersService.getRole();

        if (roles.indexOf(type) < 0) {
            this.router.navigate(['/'], {
                replaceUrl: true,
            });
            return false;
        }

        return true;
    }
}
