import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerDataSource } from 'ng2-smart-table';
import { NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';

@Injectable()
export class UsersService {
  user: any = null;

  constructor(private http: HttpClient, private authService: NbAuthService) {}

  getRole() {
    if (this.user) {
      return this.user.role;
    } else {
      this.authService
        .onTokenChange()
        .subscribe((token: NbAuthOAuth2JWTToken) => {
          if (token.isValid()) {
            this.user = token.getAccessTokenPayload(); // here we receive a payload from the token and assigns it to our `user` variable
            return this.user.role;
          }
        });
    }
  }

  register(data: any) {
    return this.http.post('/api/signup-user', data);
  }

  getUsers(): Observable<any> {
    return this.http.get('/api/users');
  }

  getUserById(id): Observable<any> {
    return this.http.get('/api/users/' + id);
  }

  addUser(params: any): Observable<any> {
    return this.http.post('/api/users', params);
  }

  editUser(params: any): Observable<any> {
    return this.http.patch('/api/users/' + params.id, params);
  }

  deteleUser(id: any): Observable<any> {
    return this.http.delete('/api/users/' + id);
  }

  getUsersSmartTable() {
    return new ServerDataSource(this.http, {
      endPoint: '/api/users',
      dataKey: 'docs',
      totalKey: 'totalDocs',
      perPage: 'limit',
      pagerPageKey: 'page',
      filterFieldKey: 'filters[#field#]',
    });
  }

  getUserProfile(): Observable<any> {
    return this.http.get('/api/settings/profile');
  }

  updateUserProfile(name): Observable<any> {
    return this.http.patch('/api/settings/profile', { name });
  }

  updateCustomer(customer): Observable<any> {
    return this.http.patch('/api/settings/account', customer);
  }
}
