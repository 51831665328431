import { NgModule } from '@angular/core';
import {
  NbMenuModule,
  NbCardModule,
  NbLayoutModule,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
  NbButtonModule,
  NbAlertModule,
  NbCheckboxModule,
} from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { RegisterComponent } from './register/register.component';
import { FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    ThemeModule,
    NbMenuModule,
    NbCardModule,
    NbLayoutModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    FormsModule,
    NbAlertModule,
    NbCheckboxModule,
    RouterModule,
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    RegisterUserComponent,
  ],
})
export class AuthenticationModule {}
