import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DefaultEditor, ViewCell, DefaultFilter } from 'ng2-smart-table';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'ngx-smart-table-datepicker',
  templateUrl: './smart-table-datepicker.component.html',
  styleUrls: ['./smart-table-datepicker.component.css'],
})
export class SmartTableDatepickerComponent
  extends DefaultEditor
  implements OnInit {
  @Input() placeholder: string = 'Choose a Date/Time';

  @Input() min: Date; // Defaults to now(rounded down to the nearest 15 minute mark)

  @Input() max: Date; // Defaults to 1 month after the min

  stringValue;
  inputModel: Date;

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.min) {
      this.min = new Date();
      this.min.setMinutes(Math.floor(this.min.getMinutes() / 15) * 15);
    }

    if (!this.max) {
      this.max = new Date(this.min);
      this.max.setFullYear(this.min.getFullYear() + 1);
    }

    if (this.cell.newValue) {
      const cellValue = new Date(this.cell.newValue);
      if (
        cellValue.getTime() >= this.min.getTime() &&
        cellValue.getTime() <= this.max.getTime()
      ) {
        this.inputModel = cellValue;
        this.cell.newValue = this.inputModel.toISOString();
      }
    }

    if (!this.inputModel) {
      this.inputModel = this.min;
      this.cell.newValue = this.inputModel.toISOString();
    }
  }

  onChange() {
    if (this.inputModel) {
      this.cell.newValue = this.inputModel.toISOString();
    }
  }
}

@Component({
  template: `{{ value | date: 'short' }}`,
})
export class SmartTableDatepickerRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;

  constructor() {}

  ngOnInit() {}
}

@Component({
  template: `
    <input
      [ngClass]="inputClass"
      [formControl]="inputControl"
      class="form-control"
      [placeholder]="column.title"
      [nbDatepicker]="picker"
      type="text"
    />
    <nb-datepicker #picker [format]="dateFormat"></nb-datepicker>
  `,
})
export class SmartTableDatepickerFilterComponent
  extends DefaultFilter
  implements OnInit, OnChanges {
  inputControl = new FormControl();
  dateFormat = 'DD[-]MM[-]YYYY';

  constructor() {
    super();
    if (
      this.column &&
      this.column.filter &&
      this.column.filter.config.dateFormat
    ) {
      this.dateFormat = this.column.filter.config.dateFormat;
    }
  }

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(this.delay))
      .subscribe((value: any) => {
        this.query =
          value !== null
            ? moment(this.inputControl.value).format('YYYY-MM-DD')
            : '';
        this.setFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.query = changes.query.currentValue;
      this.inputControl.setValue(
        this.inputControl.value ? moment(this.inputControl.value) : null,
      );
    }
  }
}
